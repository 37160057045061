import React from "react";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";
import { StaticQuery, graphql } from "gatsby";

const ComponentName = () => (
  <StaticQuery
    query={graphql`
      {
        strapiContactInfo {
          phone
        }
      }
    `}
    render={(data) => {
      const pp = data.strapiContactInfo.phone.replace(/[^0-9 ]/g, "");
      return (
        <a
          className="nolink"
          href={"https://api.whatsapp.com/send?phone=" + pp + "&text=Hello"}
          target="_blank"
          rel="noreferrer"
        >
          <div className="whatsapp-call">
            <FaWhatsapp />
          </div>
        </a>
      );
    }}
  ></StaticQuery>
);

export default ComponentName;
