import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import favicon from "../images/logo.svg";

const SEO = (props) => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const title = props.title
        ? props.title + " - Pestgogo"
        : data.allStrapiGlobal.nodes[0].defaultSeo.metaTitle;
      return (
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
          title={title}
          titleTemplate={
            props.title
              ? `%s`
              : `%s - ${data.allStrapiGlobal.nodes[0].siteName}`
          }
          link={[
            {
              rel: "shortcut icon",
              type: "image/ico",
              href: `${data.allStrapiGlobal.nodes[0].favicon.localFile.publicURL}`,
            },
          ]}
        >
          <meta
            name="description"
            content={
              props.des ||
              data.allStrapiGlobal.nodes[0].defaultSeo.metaDescription
            }
          />
          {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRf-tr_8y893XYqOMJRg5qPSUMU33o-u8&v=3.exp&libraries=geometry,drawing,places"></script> */}
        </Helmet>
      );
    }}
  />
);

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  {
    allStrapiGlobal {
      nodes {
        siteName
        defaultSeo {
          metaTitle
          metaDescription
        }
        favicon {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
